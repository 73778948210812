.passiveTab {
  transition: opacity 1s ease-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.activeTab {
  opacity: 1;
  display: block;
}

.selectedTab {
  /*  darken color*/
  background-color: #e6e6e6;
}
